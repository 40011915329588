export enum LabelType {
  Category = 'category',
}

export interface Label extends ApiEntity {
  name: string
  parentId?: id
  labelType: LabelType
}

export function fetchLabels() {
  return useJsonApiFetch<Label[]>('/api/v1/labels')
}
